/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-statements */
import chameleon from '@chamaeleonidae/chmln';
import { TextField as CustomTextField } from '@get-e/react-components';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import { Grid, Typography, InputAdornment, useMediaQuery, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
    DataGridPro,
    useGridApiRef,
    GridRowId,
    GridEventListener,
    GridColDef,
    GRID_DETAIL_PANEL_TOGGLE_FIELD,
} from '@mui/x-data-grid-pro';
import clsx from 'clsx';
import React, { useState, useCallback, useRef, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { logAmplitudeEvent } from '../../amplitude/amplitude';
import PageActionButton from '../../components/buttons/PageActionButton';
import { CustomNoRowsOverlay } from '../../components/noRowsDataGridOverlay/CustomNoRowsOverlay';
import {
    ACTIVATE_DRIVER_BUTTON,
    DEACTIVATE_DRIVER_BUTTON,
    DRIVERS_SCREEN,
    EDIT_DRIVER_BUTTON,
    NEW_DRIVER_BUTTON,
} from '../../constants/amplitude/supplierKeys';
import { COLORS } from '../../constants/colors';
import { useCurrentProfileContext } from '../../context/CurrentProfileContext';
import { useNotificationContext, Severity } from '../../context/NotificationContext';
import useDataGridStyles from '../../styles/DataGrid';
import theme from '../../styles/theme';
import AddDriverModal from '../ride/supplier/components/AddDriverModal';
import { addDriver, toggleDriverStatus, editDriver, useDrivers } from './api';
import { AddDriverRequest, Driver, EditDriverRequest } from './api/types';
import ConfirmToggleDriverActivationModal from './components/ConfirmToggleDriverActivationModal';
import DriversLoadingSkeleton from './components/DriversLoadingSkeleton';
import EditDriverModal from './components/EditDriverModal';
import { useDriversColumns } from './hooks/useDriversColumns';
import { useDriversColumnsMobile } from './hooks/useDriversColumnsMobile';

const useStyles = makeStyles(styleTheme => ({
    mainWrapper: {
        alignItems: 'center',
        padding: 0,
    },
    mainWrapperTopPadding: { paddingTop: '3.5rem' },
    headerTitleWrapper: {
        marginTop: '1rem',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        [styleTheme.breakpoints.up('md')]: {
            justifyContent: 'space-between',
            flexDirection: 'row',
            marginTop: '2rem',
        },
    },
    headerTitle: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 0,
        [styleTheme.breakpoints.down('sm')]: {
            width: '100%',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '0.5rem',
        },
    },
    content: { order: 2 },
}));

export const MOBILE_TABLE_HEIGHT = 'calc(100vh - 324px)';
export const TABLE_HEIGHT = 'calc(100vh - 263px)';

const Drivers = () => {
    const classes = useStyles();
    const dataGridClases = useDataGridStyles();
    const gridApiRef = useGridApiRef();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const { t } = useTranslation();
    const { showNotification } = useNotificationContext();
    const { currentProfile } = useCurrentProfileContext();

    const [searchPhrase, setSearchPhrase] = useState('');
    const [isAddDriverModalOpen, setIsAddDriverModalOpen] = useState(false);
    const [isConfirmToggleIsActiveModalOpen, setIsConfirmToggleIsActiveModalOpen] = useState(false);
    const [isEditDriverModalOpen, setIsEditDriverModalOpen] = useState(false);
    const [results, setResults] = useState<Driver[]>([]);
    const [isUpdateNotificationOpen] = useState(false);
    const selectedDriver = useRef<Driver>();

    const { data: drivers, isFetching, isLoading, refetch } = useDrivers();

    useEffect(() => {
        if (drivers.length === 0) {
            return;
        }

        const searchTerm = searchPhrase.toLowerCase().trim();

        const filteredDrivers = drivers.filter(
            driver => driver.name.toLowerCase().includes(searchTerm) || driver.phoneNumber.includes(searchTerm)
        );

        setResults(filteredDrivers);
    }, [searchPhrase, drivers]);

    useEffect(() => {
        logAmplitudeEvent(DRIVERS_SCREEN);

        if (window.location.hostname.includes('develop')) {
            chameleon.identify(currentProfile.id, {
                email: currentProfile.email,
                name: currentProfile.fullName,
                created: currentProfile.joinedAt,
                role: currentProfile.accountType,
            });
        }
    }, [currentProfile]);

    const columnVisibilityModel = useMemo(() => {
        return {
            phoneNumber: !isMobile,
            rideAssigned: !isMobile,
            status: !isMobile,
            actions: !isMobile,
            [GRID_DETAIL_PANEL_TOGGLE_FIELD]: isMobile,
        };
    }, [isMobile]);

    const isNoRows = useMemo(() => results.length === 0, [results.length]);

    const tableWrapperHeight = useMemo(() => {
        const height = isMobile ? MOBILE_TABLE_HEIGHT : TABLE_HEIGHT;

        return isNoRows ? 'auto' : height;
    }, [isMobile, isNoRows]);

    const tableHeight = useMemo(() => (isNoRows ? TABLE_HEIGHT : '100%'), [isNoRows]);

    const onToggleStatus = (driver: Driver) => {
        selectedDriver.current = driver;
        setIsConfirmToggleIsActiveModalOpen(true);
        const { isDeactivated } = driver;

        logAmplitudeEvent(isDeactivated ? ACTIVATE_DRIVER_BUTTON : DEACTIVATE_DRIVER_BUTTON);
    };

    const onEdit = (driver: Driver) => {
        selectedDriver.current = driver;
        setIsEditDriverModalOpen(true);
        logAmplitudeEvent(EDIT_DRIVER_BUTTON);
    };

    const columns = useDriversColumns(onToggleStatus, onEdit);

    const handleAddDriver = async (newDriver: AddDriverRequest) => {
        try {
            await addDriver(newDriver);
            showNotification(t('pages.drivers.driverSuccessfullyCreated'), Severity.Info);
        } catch (error: any) {
            showNotification(error?.response?.data?.message || t('errors.defaultError'), Severity.Error);
        } finally {
            setIsAddDriverModalOpen(false);
            await refetch();
        }
    };

    const handleEditDriver = async (updatedDriver: EditDriverRequest) => {
        try {
            await editDriver(updatedDriver);
            showNotification(t('pages.drivers.driverSuccessfullyUpdated'), Severity.Info);
        } catch (error) {
            showNotification(t('errors.defaultError'), Severity.Error);
        } finally {
            setIsEditDriverModalOpen(false);
            await refetch();
        }
    };

    const handleToggleActivation = async (id: number, isDeactivated: boolean) => {
        try {
            await toggleDriverStatus(id.toString(), !isDeactivated);
            showNotification(
                t(isDeactivated ? 'pages.drivers.driverSuccessfullyActivated' : 'pages.drivers.driverSuccessfullyDeactivated'),
                Severity.Info
            );
        } catch (error) {
            showNotification(t('errors.defaultError'), Severity.Error);
        } finally {
            setIsConfirmToggleIsActiveModalOpen(false);
            setIsEditDriverModalOpen(false);
            await refetch();
        }
    };

    const onRowClick = useCallback<GridEventListener<'rowClick'>>(
        (params: { id: GridRowId; row: Driver }) => {
            if (isMobile) {
                gridApiRef.current.toggleDetailPanel(params.id);
            } else {
                onEdit(params.row);
            }
        },
        [gridApiRef, isMobile]
    );

    const detailPanelContent = useDriversColumnsMobile(onToggleStatus, onEdit);

    if (isLoading) {
        return <DriversLoadingSkeleton />;
    }

    return (
        <Grid container className={clsx(classes.mainWrapper, { [classes.mainWrapperTopPadding]: isUpdateNotificationOpen })}>
            <Grid item xs={12} className={classes.headerTitleWrapper}>
                <Grid item className={classes.headerTitle}>
                    <Typography
                        style={{
                            color: COLORS.BLUE,
                            fontSize: '1.5rem',
                            fontWeight: 700,
                            paddingRight: '1rem',
                        }}
                    >
                        {t('pages.drivers.drivers')}
                    </Typography>
                </Grid>
                <Grid item>
                    <PageActionButton
                        onClick={() => {
                            setIsAddDriverModalOpen(true);
                            logAmplitudeEvent(NEW_DRIVER_BUTTON);
                        }}
                        isLoading={isFetching}
                    >
                        <AddIcon style={{ paddingRight: '0.5rem' }} />
                        {t('pages.drivers.newDriver')}
                    </PageActionButton>
                </Grid>
            </Grid>
            <Grid container sx={{ marginTop: '2rem' }}>
                <Grid item xs={12} md={8} xl={8}>
                    <CustomTextField
                        id="searchByNameOrPhoneNumber"
                        autoComplete="off"
                        value={searchPhrase}
                        noHelperTextSpace
                        onChange={event => {
                            setSearchPhrase(event.target.value);
                        }}
                        label={t('pages.drivers.searchByNameOrPhoneNumber')}
                        name="search"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
            </Grid>
            <Box
                sx={{
                    width: '100%',
                    height: tableWrapperHeight,
                }}
            >
                <DataGridPro
                    apiRef={gridApiRef}
                    style={{ height: tableHeight }}
                    className={clsx({
                        [dataGridClases.dataGrid]: true,
                        [dataGridClases.hideHeader]: isMobile,
                        [dataGridClases.dataGridNoRows]: isNoRows,
                    })}
                    hideFooter
                    loading={isFetching}
                    disableColumnSelector
                    rows={results}
                    columns={columns as GridColDef[]}
                    slots={{ noRowsOverlay: CustomNoRowsOverlay }}
                    getDetailPanelContent={detailPanelContent}
                    getDetailPanelHeight={() => 'auto'}
                    onRowClick={onRowClick}
                    columnVisibilityModel={columnVisibilityModel}
                />
            </Box>
            {isAddDriverModalOpen && (
                <AddDriverModal
                    isModalOpen={isAddDriverModalOpen}
                    onClose={() => setIsAddDriverModalOpen(false)}
                    onAddDriver={handleAddDriver}
                    isSaving={false}
                />
            )}
            {isEditDriverModalOpen && selectedDriver.current && (
                <EditDriverModal
                    driver={selectedDriver.current}
                    isModalOpen={isEditDriverModalOpen}
                    onClose={() => setIsEditDriverModalOpen(false)}
                    onEditDriver={handleEditDriver}
                    isSaving={false}
                    onDeactivateClick={() => setIsConfirmToggleIsActiveModalOpen(true)}
                />
            )}
            {isConfirmToggleIsActiveModalOpen && selectedDriver.current && (
                <ConfirmToggleDriverActivationModal
                    id={selectedDriver.current.id}
                    isDeactivated={selectedDriver.current.isDeactivated ?? false}
                    onClose={() => setIsConfirmToggleIsActiveModalOpen(false)}
                    onToggle={handleToggleActivation}
                />
            )}
        </Grid>
    );
};

export default Drivers;
