/* eslint-disable @typescript-eslint/init-declarations */
/* eslint-disable max-lines-per-function */
/* eslint-disable complexity */
/* eslint-disable max-statements */
import { MultipleSelect, NoResultsDataGrid as NoResults } from '@get-e/react-components';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import { Box, Grid, Typography, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { DataGridPro, GridColDef, GridRowParams } from '@mui/x-data-grid-pro';
import { DateRangePicker, DateRange, LocalizationProvider, SingleInputDateRangeField } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import clsx from 'clsx';
import dayjs, { Dayjs } from 'dayjs';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';

import { logAmplitudeEvent } from '../../../amplitude/amplitude';
import { RIDES_PAGE } from '../../../constants/amplitude/commonKeys';
import {
    DRIVER_AND_VEHICLE_BUTTON,
    SINGLE_RIDE_ADD_DRIVER,
    SINGLE_RIDE_ADD_REMARKS,
    SINGLE_RIDE_CHANGE_DRIVER,
    SINGLE_RIDE_CLOSE,
    SINGLE_RIDE_CONFIRM,
    SINGLE_RIDE_DOWNLOAD_DOCUMENTATION,
    SINGLE_RIDE_DRIVER_UNASSIGN,
} from '../../../constants/amplitude/supplierKeys';
import { COLORS } from '../../../constants/colors';
import { DATE_FORMATS } from '../../../constants/dateFormats';
import { datePickerPlaceholder } from '../../../constants/datePickerPlaceholder';
import { getSingleRideRoute } from '../../../constants/urlPaths';
import { SUPPLIER_RIDES_FILTER } from '../../../constants/windowStorageKeys';
import { Severity, useNotificationContext } from '../../../context/NotificationContext';
import { isLandscape } from '../../../helpers/isLandscape';
import {
    getRideStatusKey,
    getSupplierRidesFilter,
    resetSupplierRidesFilter,
    RideStatus,
    RideStatusSupplierFilter,
    useRideStatusSupplierMap,
} from '../../../helpers/rideStatusUtil';
import useDataGridStyles from '../../../styles/DataGrid';
import theme from '../../../styles/theme';
import { RideAmplitudeEventProperties, RideResponse } from '../../ride/api/types';
import ConfirmRideModal from '../../ride/supplier/components/ConfirmRideModal';
import DriverVehicleModal from '../../ride/supplier/components/DriverVehicleModal';
import downloadRideOrder from '../api/downloadRideOrder';
import exportRidesToExcel from '../api/exportRidesToExcel';
import { useRides } from '../api/useRides';
import { CustomFooter } from '../components/CustomFooter';
import ExportButton from '../components/ExportButton';
import { ResetFilterButton } from '../components/ResetFiltersButton';
import useRidesStyles from '../components/Rides.styles';
import RidesLoadingSkeleton from '../components/RidesLoadingSkeleton';
import { unassignDriverFromRide } from './api';
import { RideRow } from './api/types';
import AddDriverModalRidesOverview from './components/AddDriverModalRidesOverview';
import ChangeDriverModalRidesOverview from './components/ChangeDriverModalRidesOverview';
import CloseRideModal from './components/CloseRideModal';
import MobileList from './components/MobileList';
import ReferencesAndRemarksModal from './components/ReferencesAndRemarksModal';
import RemoveDriverConfirmModal from './components/RemoveDriverConfirmModal';
import SearchInput from './components/SearchInput';
import { RidesContext } from './context/RidesContext';
import getAmplitudeEventProperies from './helpers/getAmplitudeEventProperties';
import { handleRideMiddleMouseClick } from './helpers/handleRideMiddleMouseClick';
import { mapRidesToRows } from './helpers/mapRidesToRows';
import { RideChangeStatusOptionsMap } from './hooks/ridesChangeStatusUtil';
import { useRidesColumns } from './hooks/useRidesColumns';

const useStyles = makeStyles({
    resetMobile: {
        marginBottom: '2rem',
        marginTop: '1rem',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    fullScreenLoader: {
        height: 'calc(100vh - 300px)',
        width: '100%',
        display: 'flex',
        '& > div ': {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            '& > div': { minHeight: 'auto' },
        },
    },
});

export enum RideAction {
    EDIT_RIDE,
    SEND_CONFIRMATION,
    DOWNLOAD_CONFIRMATION,
}

let searchTimeoutId: ReturnType<typeof setTimeout> | number = -1;

const Rides = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const classes = useStyles();
    const ridesClasses = useRidesStyles();
    const dataGridClases = useDataGridStyles();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
    const { showNotification } = useNotificationContext();
    const previousFilterState = getSupplierRidesFilter();
    const [searchTerm, setSearchTerm] = useState('');
    const isPageOpened = useRef(false);
    const rideStatusSupplierMap = useRideStatusSupplierMap();

    const [debouncedSearchPhrase, setDebouncedSearchPhrase] = useState('');
    const [isSavingRemove, setIsSavingRemove] = useState(false);
    const [updatedRideData, setUpdatedRideData] = useState<RideResponse>();

    const [statusIds, setStatusIds] = useState<string[]>(
        previousFilterState.status.map(value => getRideStatusKey(value) as string)
    );

    const [selectedDateRange, setSelectedDateRange] = useState<DateRange<Dayjs>>(previousFilterState.dateRange);

    const [isDriverAndVehicleModalOpen, setIsDriverAndVehicleModalOpen] = useState(false);
    const [isConfirmRideModalOpen, setIsConfirmRideModalOpen] = useState(false);
    const [isCloseRideModalOpen, setIsCloseRideModalOpen] = useState(false);
    const [isReferencesModalOpen, setIsReferencesModalOpen] = useState(false);
    const [isAddDriverModalOpen, setIsAddDriverModalOpen] = useState(false);
    const [isRemoveDriverConfirmModalOpen, setIsRemoveDriverConfirmModalOpen] = useState(false);
    const [statusFilterKey, setStatusFilterKey] = useState(0);
    const [driverToRemove, setDriverToRemove] = useState<{ rideId: string; driverId: string }>();
    const [isChangeDriverModalOpen, setIsChangeDriverModalOpen] = useState(false);
    const [isInitialLoad, setIsInitialLoad] = useState(true);
    const rideDataRef = useRef<RideResponse>();
    const amplitudeEventProperties = useRef<RideAmplitudeEventProperties>();

    const {
        data: rideResponse,
        refetch,
        isRefetching,
        isLoading,
    } = useRides({
        query: debouncedSearchPhrase,
        statusFilters: statusIds?.filter(el => el),
        startDate: dayjs(selectedDateRange[0])
            .set('hour', 0)
            .set('minute', 0)
            .set('second', 0)
            .format(DATE_FORMATS['YYYY-MM-DDT00:00:00']),
        endDate: dayjs(selectedDateRange[1])
            .set('hour', 0)
            .set('minute', 0)
            .set('second', 0)
            .format(DATE_FORMATS['YYYY-MM-DDT00:00:00']),
    });

    const { mutate: exportRidesToExcelMutation, isLoading: isLoadingExport } = useMutation(exportRidesToExcel);

    const { mutate: downloadRideOrderMutation } = useMutation(downloadRideOrder);

    useEffect(() => {
        if (!isLoading && isInitialLoad) {
            setIsInitialLoad(false);
        }
    }, [isLoading, isInitialLoad]);

    const onSetStatusIds = (ids: string[]): void => {
        const filters = JSON.stringify({
            ...previousFilterState,
            status: ids.map(value => RideStatusSupplierFilter[value] as string),
        });

        localStorage.setItem(SUPPLIER_RIDES_FILTER, filters);

        setStatusIds(ids);
    };

    const handleChangeStatusButtonClick = useCallback(
        (rideId: string, optionsMap: RideChangeStatusOptionsMap) => {
            const ride = rideResponse.find(singleRide => singleRide.unid === rideId);

            rideDataRef.current = ride;

            amplitudeEventProperties.current = getAmplitudeEventProperies(ride);

            switch (optionsMap.mainButton) {
                case 'CONFIRM_RIDE':
                    setIsConfirmRideModalOpen(true);
                    logAmplitudeEvent(SINGLE_RIDE_CONFIRM, amplitudeEventProperties.current);
                    break;
                case 'CLOSE_RIDE':
                    setIsCloseRideModalOpen(true);
                    logAmplitudeEvent(SINGLE_RIDE_CLOSE, amplitudeEventProperties.current);
                    break;
                case 'ADD_DRIVER':
                    setIsAddDriverModalOpen(true);
                    logAmplitudeEvent(SINGLE_RIDE_ADD_DRIVER, amplitudeEventProperties.current);
                    break;
                case 'CHANGE_DRIVER':
                    setIsChangeDriverModalOpen(true);
                    logAmplitudeEvent(SINGLE_RIDE_CHANGE_DRIVER, amplitudeEventProperties.current);
                    break;
                case 'DRIVER_AND_VEHICLE':
                    setIsDriverAndVehicleModalOpen(true);
                    logAmplitudeEvent(DRIVER_AND_VEHICLE_BUTTON, amplitudeEventProperties.current);
                    break;
                default:
                    throw Error('Unexpected change status');
            }
        },
        [rideResponse]
    );

    const handleReferenceAndRemarks = useCallback(
        (data: RideRow) => {
            rideDataRef.current = data as unknown as RideResponse;

            const ride = rideResponse.find(singleRide => singleRide.unid === data.id);

            amplitudeEventProperties.current = getAmplitudeEventProperies(ride);

            setIsReferencesModalOpen(true);
            logAmplitudeEvent(SINGLE_RIDE_ADD_REMARKS, amplitudeEventProperties.current);
        },
        [rideResponse]
    );

    const handleDownloadRideOrder = useCallback(
        (id: string) => {
            const ride = rideResponse.find(singleRide => singleRide.unid === id);

            amplitudeEventProperties.current = getAmplitudeEventProperies(ride);

            downloadRideOrderMutation(id);
            logAmplitudeEvent(SINGLE_RIDE_DOWNLOAD_DOCUMENTATION, amplitudeEventProperties.current);
        },
        [downloadRideOrderMutation, rideResponse]
    );

    const handleRemoveDriver = useCallback((rideId: string, driverId: string) => {
        setIsRemoveDriverConfirmModalOpen(true);
        setDriverToRemove({
            rideId,
            driverId,
        });
    }, []);

    const handleDriverAndVehicle = useCallback(
        (rideId: string) => {
            const ride = rideResponse.find(singleRide => singleRide.unid === rideId);

            rideDataRef.current = ride as unknown as RideResponse;

            setIsDriverAndVehicleModalOpen(true);
        },
        [rideResponse]
    );

    const onConfirmRemoveDriver = async () => {
        let updatedRide: RideResponse | undefined;

        try {
            const ride = driverToRemove ? rideResponse.find(singleRide => singleRide.unid === driverToRemove.rideId) : undefined;

            amplitudeEventProperties.current = getAmplitudeEventProperies(ride);

            setIsSavingRemove(true);

            if (driverToRemove) {
                const { data } = await unassignDriverFromRide(driverToRemove.rideId);

                updatedRide = data;
            }

            showNotification(t('alert.edit.successRemoveDriver'), Severity.Info);
            logAmplitudeEvent(SINGLE_RIDE_DRIVER_UNASSIGN, amplitudeEventProperties.current);
        } catch (error) {
            showNotification(t('alert.driverUnassignError'), Severity.Error);
        } finally {
            setIsRemoveDriverConfirmModalOpen(false);
            setIsSavingRemove(false);
            await handleConfirmCloseAddRemarksFinished(updatedRide);
        }
    };

    const [desktopColumns] = useRidesColumns(
        handleChangeStatusButtonClick,
        handleReferenceAndRemarks,
        handleDownloadRideOrder,
        handleRemoveDriver,
        handleDriverAndVehicle
    );

    useEffect(() => {
        document.addEventListener('mouseup', handleRideMiddleMouseClick);
        return () => document.removeEventListener('mouseup', handleRideMiddleMouseClick);
    }, [rideResponse]);

    const handleRowClick = useCallback((row: RideRow | GridRowParams, event?: React.MouseEvent<HTMLElement>) => {
        if (event && event.button === 0 && event.metaKey) {
            event.preventDefault();
            window.open(getSingleRideRoute(row.id.toString()), '_blank');
            return;
        }

        history.push(getSingleRideRoute(row.id.toString()));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleConfirmCloseAddRemarksFinished = async (updatedRide?: RideResponse) => {
        await refetch();

        setTimeout(() => {
            if (updatedRide) {
                setUpdatedRideData(updatedRide);
            }
        }, 500);
    };

    const handleExport = (): void => {
        const rideResultIds = rideResponse?.map(ride => ride.unid);

        if (selectedDateRange[0] === null || selectedDateRange[1] === null) {
            showNotification(t('alert.dateRangeRequired'), Severity.Error);
            return;
        }

        exportRidesToExcelMutation({
            startDate: dayjs(selectedDateRange[0]).format(DATE_FORMATS['YYYY-MM-DDTHH:mm:ss']),
            endDate: dayjs(selectedDateRange[1]).format(DATE_FORMATS['YYYY-MM-DDTHH:mm:ss']),
            tripUnids: rideResultIds,
        });
    };

    const dataRows = useMemo(() => {
        if (rideResponse) {
            return mapRidesToRows({
                rows: rideResponse,
                updatedRide: updatedRideData,
                statusFilterKeys: statusIds as Array<keyof typeof RideStatus>,
            });
        }

        return [];
    }, [rideResponse, statusIds, updatedRideData]);

    const handleResetFilters = () => {
        const filters = resetSupplierRidesFilter();
        const filterStatusIds = filters.status.map(value => getRideStatusKey(value) as string);

        setStatusIds(filterStatusIds);
        setSelectedDateRange(filters.dateRange);
        setStatusFilterKey(Math.random());
    };

    const numberedValues = useMemo(() => {
        if (rideResponse && (statusIds.includes('TO_CONFIRM') || statusIds.length === 0)) {
            const filteredRides = rideResponse.filter(
                ride => ride.status === 'TO_CONFIRM' || ride.status === 'TO_CONFIRM_CHANGE'
            );

            return new Map([['TO_CONFIRM', filteredRides.length.toString()]]);
        }

        return new Map([['TO_CONFIRM', '0']]);
    }, [rideResponse, statusIds]);

    const calculateOverflowY = useMemo(() => {
        if (isMobile) {
            return isLandscape() ? 'none' : 'auto';
        }

        return 'initial';
    }, [isMobile]);

    const handleSearchInputOnChange = useCallback(
        (value: string) => {
            setSearchTerm(value);
            clearTimeout(searchTimeoutId);
            searchTimeoutId = setTimeout(() => {
                setDebouncedSearchPhrase(value);
            }, 600);
        },
        [setDebouncedSearchPhrase]
    );

    if (!isPageOpened.current) {
        isPageOpened.current = true;
        logAmplitudeEvent(RIDES_PAGE);
    }

    if (isInitialLoad && isLoading) {
        return <RidesLoadingSkeleton />;
    }

    return (
        <RidesContext.Provider value={{ searchPhrase: searchTerm }}>
            <Grid
                container
                alignItems="flex-start"
                sx={{
                    padding: ['1rem', '1rem', '0 2rem'],
                    paddingBottom: ['4rem', '2rem', '2rem'],
                }}
            >
                <Grid item xs>
                    <Typography
                        sx={{
                            color: COLORS.BLUE,
                            fontSize: '1.5rem',
                            fontWeight: 700,
                        }}
                    >
                        {t('rides')}
                    </Typography>
                </Grid>
                <Grid container marginTop={isMobile ? '1rem' : '2rem'}>
                    <Grid
                        item
                        xs={12}
                        md={isMobile ? 12 : 8}
                        lg={isMobile ? 12 : 8}
                        xl={8}
                        marginBottom={isMobile ? '0.75rem' : 'initial'}
                    >
                        <SearchInput onChange={handleSearchInputOnChange} />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={4}
                        xl={4}
                        display="flex"
                        sx={{
                            paddingLeft: isMobile ? 0 : ['0', '0', '2rem'],
                            justifyContent: 'flex-end',
                        }}
                    >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateRangePicker
                                label={t('dateRangeLocal')}
                                sx={{ width: '100%' }}
                                value={selectedDateRange}
                                onChange={newValue => {
                                    setSelectedDateRange(newValue);

                                    const filters = JSON.stringify({
                                        ...previousFilterState,
                                        dateRange: newValue,
                                    });

                                    localStorage.setItem(SUPPLIER_RIDES_FILTER, filters);
                                }}
                                localeText={{
                                    start: t('pages.rides.startingDate'),
                                    end: t('pages.rides.endingDate'),
                                    ...datePickerPlaceholder,
                                }}
                                slotProps={{
                                    textField: {
                                        variant: 'filled',
                                        placeholder: 'dd mmm y',
                                        InputProps: {
                                            endAdornment: <CalendarTodayOutlinedIcon style={{ color: COLORS.SLATE_GREY }} />,
                                        },
                                    },
                                }}
                                format={DATE_FORMATS['DD MMM YYYY']}
                                slots={{ field: SingleInputDateRangeField }}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} className={ridesClasses.filterButtonsWrapper}>
                        <MultipleSelect
                            key={statusFilterKey}
                            classNames={{
                                buttonsWrapper: ridesClasses.multipleSelectButtonsWrapper,
                                numberedIcon: ridesClasses.numberedIcon,
                            }}
                            value={statusIds}
                            values={rideStatusSupplierMap}
                            onSetIds={onSetStatusIds}
                            defaultSelectedValue={previousFilterState.status.map(filter => t(filter))}
                            displayAsButtons
                            numberedValues={numberedValues}
                        />
                        <div className={clsx(ridesClasses.resultsFilterButtonWrapper, { [classes.resetMobile]: isMobile })}>
                            <ResetFilterButton handleResetFilters={handleResetFilters} />
                            {isMobile && rideResponse.length > 0 && (
                                <Typography color={COLORS.SLATE_GREY}>
                                    {`${rideResponse.length.toString()} ${t('rides').toLowerCase()}`}
                                </Typography>
                            )}
                        </div>
                    </Grid>
                </Grid>
                <Grid container position="relative">
                    <Box
                        sx={{
                            width: '100%',
                            height: `calc(100vh - ${isMobile ? '400px' : '300px'})`,
                            overflowY: calculateOverflowY,
                        }}
                    >
                        {!isMobile || dataRows?.length === 0 ? (
                            <DataGridPro
                                loading={isLoading || isRefetching}
                                className={clsx({
                                    [dataGridClases.hideColumnHeaderRow]: isMobile,
                                    [dataGridClases.dataGrid]: true,
                                    [dataGridClases.font14]: true,
                                    [dataGridClases.dataGridNoRows]: dataRows?.length === 0,
                                })}
                                hideFooterRowCount
                                disableColumnSelector
                                disableColumnFilter
                                rows={dataRows}
                                columns={desktopColumns as unknown as GridColDef[]}
                                getRowHeight={() => 'auto'}
                                onRowClick={params => handleRowClick(params)}
                                slots={{
                                    noRowsOverlay: () => (
                                        <NoResults
                                            text={t('pages.rides.noResults.text')}
                                            description={t('pages.rides.noResults.description')}
                                            additionalContent={
                                                <div className={ridesClasses.noResultsContentWrapper}>
                                                    <ResetFilterButton handleResetFilters={handleResetFilters} />
                                                </div>
                                            }
                                        />
                                    ),
                                    footer: () =>
                                        dataRows?.length > 0 && !isMobile
                                            ? CustomFooter({
                                                  handleExport,
                                                  totalCount: dataRows.length,
                                                  isLoadingExport,
                                              })
                                            : null,
                                }}
                                sx={{ overflowX: 'scroll' }}
                            />
                        ) : (
                            <MobileList
                                data={dataRows as RideRow[]}
                                handleChangeStatusButtonClick={handleChangeStatusButtonClick}
                                handleReferenceAndRemarks={handleReferenceAndRemarks}
                                handleDownloadRideOrder={handleDownloadRideOrder}
                                handleRemoveDriver={handleRemoveDriver}
                                handleDriverAndVehicle={handleDriverAndVehicle}
                                handleRowClick={handleRowClick}
                            />
                        )}
                    </Box>
                    {isMobile && dataRows.length > 0 && (
                        <ExportButton onClick={() => !isLoadingExport && handleExport()} isDisabled={isLoadingExport} />
                    )}
                </Grid>
            </Grid>
            {isConfirmRideModalOpen && rideDataRef.current && (
                <ConfirmRideModal
                    isModalOpen={isConfirmRideModalOpen}
                    onClose={() => setIsConfirmRideModalOpen(false)}
                    onFinished={handleConfirmCloseAddRemarksFinished}
                    ride={rideDataRef.current}
                    supplierReference={rideDataRef.current.supplierReference ?? undefined}
                />
            )}
            {isCloseRideModalOpen && rideDataRef.current && (
                <CloseRideModal
                    isModalOpen={isCloseRideModalOpen}
                    onClose={() => setIsCloseRideModalOpen(false)}
                    onFinished={handleConfirmCloseAddRemarksFinished}
                    ride={rideDataRef.current}
                    supplierReference={rideDataRef.current.supplierReference ?? undefined}
                />
            )}
            {isReferencesModalOpen && rideDataRef.current && (
                <ReferencesAndRemarksModal
                    rideId={rideDataRef.current.unid}
                    isModalOpen={isReferencesModalOpen}
                    onClose={() => setIsReferencesModalOpen(false)}
                    onFinished={handleConfirmCloseAddRemarksFinished}
                    supplierReference={rideDataRef.current.supplierReference ?? undefined}
                />
            )}
            {isAddDriverModalOpen && rideDataRef.current?.unid && (
                <AddDriverModalRidesOverview
                    isModalOpen={isAddDriverModalOpen}
                    onClose={() => setIsAddDriverModalOpen(false)}
                    rideId={rideDataRef.current?.unid}
                    onFinished={handleConfirmCloseAddRemarksFinished}
                    amplitudeEventProperties={amplitudeEventProperties.current}
                />
            )}
            {isChangeDriverModalOpen && rideDataRef?.current?.unid && rideDataRef?.current?.driver && (
                <ChangeDriverModalRidesOverview
                    isModalOpen={isChangeDriverModalOpen}
                    onClose={() => setIsChangeDriverModalOpen(false)}
                    rideId={rideDataRef?.current?.unid}
                    initialDriver={rideDataRef?.current?.driver}
                    onFinished={handleConfirmCloseAddRemarksFinished}
                    amplitudeEventProperties={amplitudeEventProperties.current}
                />
            )}
            {isRemoveDriverConfirmModalOpen && (
                <RemoveDriverConfirmModal
                    onClose={() => setIsRemoveDriverConfirmModalOpen(false)}
                    onConfirm={onConfirmRemoveDriver}
                    isLoading={isSavingRemove}
                />
            )}
            {isDriverAndVehicleModalOpen && (
                <DriverVehicleModal
                    rideStatus={rideDataRef?.current?.status}
                    driverVehicleId={rideDataRef?.current?.driverVehicleId}
                    isRidesOverview
                    rideDataId={rideDataRef?.current?.unid}
                    initialVehicle={null}
                    initialDriver={rideDataRef?.current?.driver || null}
                    isModalOpen={isDriverAndVehicleModalOpen}
                    onClose={() => {
                        refetch?.();
                        setIsDriverAndVehicleModalOpen(false);
                    }}
                />
            )}
        </RidesContext.Provider>
    );
};

export default Rides;
