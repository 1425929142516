import { PrimaryButton, TableBody, TableContainer, TableHead, NoResultsDataGrid as NoResults } from '@get-e/react-components';
import AddIcon from '@mui/icons-material/Add';
import { FormControlLabel, Grid, Switch, Table, TableCell, TableRow, Typography, useMediaQuery } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import dayjs from 'dayjs';
import React, { FunctionComponent, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DUMMY_DATE } from '../../constants';
import { COLORS } from '../../constants/colors';
import { BUTTON_WIDTH, MIN_BUTTON_WIDTH } from '../../constants/layout';
import { useCurrentProfileContext } from '../../context/CurrentProfileContext';
import { useLocaleContext } from '../../context/LocaleContext';
import { getWhenMessage, resolveRepeatsInitialValue } from '../../helpers/repeats';
import theme from '../../styles/theme';
import { deleteStopSalesRule, useCurrentStopSalesRules, usePastStopSalesRules } from './api';
import AvailabilityLoadingSkeleton from './components/AvailabilityLoadingSkeleton';
import CreateAvailabilityModal from './components/CreateAvailabilityModal';
import TableRowDesktop from './components/TableRowDesktop';
import TableRowMobile from './components/TableRowMobile';

const useStyles = ({ isButtonWidthFitContent }: { isButtonWidthFitContent: boolean }) =>
    makeStyles({
        root: {
            margin: '0',
            padding: '2rem',
        },
        rootMobile: {
            margin: '0 auto',
            padding: '1rem',
        },
        pageHeader: {
            display: 'flex',
            alignItems: 'center',
            paddingBottom: '2rem',
            color: COLORS.BLACK,
        },
        tableHead: { height: '56px' },
        pageHeaderMobile: {
            display: 'flex',
            flexDirection: 'column',
            paddingBottom: '2rem',
            color: COLORS.BLACK,
        },
        tableContainer: { background: COLORS.WHITE },
        tableCell: {
            color: COLORS.BLACK,
            background: COLORS.WHITE,
        },
        addButton: {
            width: isButtonWidthFitContent ? 'fit-content' : BUTTON_WIDTH,
            minWidth: isButtonWidthFitContent ? MIN_BUTTON_WIDTH : 'auto',
            paddingLeft: '0.25rem',
            paddingRight: '0.25rem',
        },
    });

const Availability: FunctionComponent = () => {
    const { locale } = useLocaleContext();
    const classes = useStyles({ isButtonWidthFitContent: locale === 'es-ES' })();
    const [showPastPeriod, setShowPastPeriod] = useState(false);
    const mobileView = useMediaQuery(theme.breakpoints.down('lg'));
    const { currentProfile: currentUser } = useCurrentProfileContext();
    const { t } = useTranslation();
    const selectedBlockedPeriodId = useRef('');
    const [isCreateAvailabilityModalOpen, setIsCreateAvailabilityModalOpen] = useState(false);

    const {
        data: currentStopSalesRules,
        isFetching: isfetchingCurrent,
        refetch: refetchCurrent,
    } = useCurrentStopSalesRules(currentUser.accountId?.toString() ?? '', !showPastPeriod);

    const {
        data: pastStopSalesRules,
        isFetching: isfetchingPast,
        refetch: refetchPast,
    } = usePastStopSalesRules(currentUser.accountId?.toString() ?? '', showPastPeriod);

    const data = showPastPeriod ? pastStopSalesRules : currentStopSalesRules;

    const handleAddBlockPeriod = () => {
        selectedBlockedPeriodId.current = '';
        setIsCreateAvailabilityModalOpen(true);
    };

    const handleEditBlockPeriod = (id: number) => {
        selectedBlockedPeriodId.current = id.toString();
        setIsCreateAvailabilityModalOpen(true);
    };

    const handleDeleteBlockPeriod = async (id: number) => {
        await deleteStopSalesRule(currentUser.accountId?.toString() ?? '', id.toString());
        showPastPeriod ? await refetchPast() : await refetchCurrent();
    };

    const togglePastPeriod = (isChecked: boolean) => {
        setShowPastPeriod(isChecked);
    };

    const handleAvailabilitySubmit = async () => {
        setIsCreateAvailabilityModalOpen(false);
        showPastPeriod ? await refetchPast() : await refetchCurrent();
    };

    if (isfetchingCurrent || isfetchingPast) {
        return <AvailabilityLoadingSkeleton />;
    }

    return (
        <Grid
            container
            alignItems="flex-start"
            sx={{
                padding: {
                    xs: '1rem',
                    md: '0 2rem 2rem 2rem',
                    xl: '2rem',
                },
            }}
        >
            <Grid item xs={6} md={8} xl={8}>
                <Typography
                    sx={{
                        color: COLORS.BLUE,
                        fontSize: '1.5rem',
                        fontWeight: 700,
                    }}
                >
                    {t('availability')}
                </Typography>
            </Grid>
            <Grid
                item
                xs={6}
                md={4}
                xl={4}
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    margin: '0',
                }}
            >
                <PrimaryButton className={classes.addButton} onClick={handleAddBlockPeriod} icon={<AddIcon />}>
                    {t('blockPeriod')}
                </PrimaryButton>
            </Grid>
            <Grid container marginTop="2rem">
                <Grid item xs={12}>
                    <TableContainer className={classes.tableContainer}>
                        <Table size="small">
                            <TableHead columns={mobileView ? 2 : 3} className={classes.tableHead}>
                                <TableRow>
                                    <TableCell
                                        className={classes.tableCell}
                                        style={{
                                            paddingRight: '0.5rem',
                                            paddingLeft: '0.5rem',
                                            color: COLORS.BLACK,
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                lineHeight: '2rem',
                                                borderRight: mobileView ? 'none' : `1px solid ${COLORS.BLACK_12}`,
                                            }}
                                        >
                                            {t('pages.availability.description')}
                                        </Typography>
                                    </TableCell>
                                    {!mobileView && (
                                        <TableCell className={classes.tableCell} style={{ paddingLeft: '0' }}>
                                            <Typography sx={{ lineHeight: '2rem' }}>{t('when')}</Typography>
                                        </TableCell>
                                    )}
                                    <TableCell
                                        style={{
                                            textAlign: 'right',
                                            paddingRight: '0.5rem',
                                        }}
                                    >
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={showPastPeriod}
                                                    onChange={event => togglePastPeriod(event.target.checked)}
                                                />
                                            }
                                            label={t('pages.availability.showPastPeriod')}
                                            sx={{
                                                marginLeft: 'auto',
                                                marginRight: '0',
                                                color: COLORS.BLACK,
                                            }}
                                        />
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {!data.length && !isfetchingCurrent && !isfetchingPast && (
                                    <TableRow>
                                        <TableCell colSpan={3}>
                                            <NoResults />
                                        </TableCell>
                                    </TableRow>
                                )}
                                {data?.map(blockedPeriod => {
                                    const repeats = resolveRepeatsInitialValue(blockedPeriod.applicableOnDays);

                                    const blockedPeriodMessage = getWhenMessage(
                                        repeats,
                                        dayjs(blockedPeriod.start).toDate(),
                                        dayjs(blockedPeriod.end).toDate(),
                                        dayjs(`${DUMMY_DATE} ${blockedPeriod.applicableStartTime}`).toDate(),
                                        dayjs(`${DUMMY_DATE} ${blockedPeriod.applicableEndTime}`).toDate(),
                                        blockedPeriod.applicableOnDays ?? []
                                    );

                                    if (mobileView) {
                                        return (
                                            <TableRowMobile
                                                key={blockedPeriod.id}
                                                repeats={repeats}
                                                blockedPeriod={blockedPeriod}
                                                blockedPeriodMessage={blockedPeriodMessage}
                                                handleEditBlockPeriod={handleEditBlockPeriod}
                                                handleDeleteBlockPeriod={handleDeleteBlockPeriod}
                                            />
                                        );
                                    }

                                    return (
                                        <TableRowDesktop
                                            key={blockedPeriod.id}
                                            repeats={repeats}
                                            blockedPeriod={blockedPeriod}
                                            blockedPeriodMessage={blockedPeriodMessage}
                                            handleEditBlockPeriod={handleEditBlockPeriod}
                                            handleDeleteBlockPeriod={handleDeleteBlockPeriod}
                                        />
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
            {isCreateAvailabilityModalOpen && (
                <CreateAvailabilityModal
                    blockedPeriodId={selectedBlockedPeriodId.current}
                    isModalOpen={isCreateAvailabilityModalOpen}
                    onClose={() => setIsCreateAvailabilityModalOpen(false)}
                    onSubmit={handleAvailabilitySubmit}
                />
            )}
        </Grid>
    );
};

export default Availability;
