import dayjs from 'dayjs';

import { DATE_FORMATS } from '../constants/dateFormats';

export const getDateTimeWithoutOffsetFormatted = (dateTime: string, dateTimeFormat?: string) => {
    const parsedDateTime = dayjs.tz(dateTime);
    const utcOffset = parsedDateTime.utcOffset();

    return parsedDateTime.utcOffset(utcOffset).format(dateTimeFormat || DATE_FORMATS['YYYY-MM-DDTHH:mm:ss']);
};

export const getDateTimeWithoutOffset = (dateTime: string) => {
    const formattedDateTime = getDateTimeWithoutOffsetFormatted(dateTime, DATE_FORMATS['YYYY/MM/DD HH:mm:ss']);

    return new Date(formattedDateTime);
};
