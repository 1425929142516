/* eslint-disable max-statements */
import { TextField as CustomTextField, NoResultsDataGrid as NoResults } from '@get-e/react-components';
import AddIcon from '@mui/icons-material/Add';
import DirectionsCarFilledIcon from '@mui/icons-material/DirectionsCarFilled';
import SearchIcon from '@mui/icons-material/Search';
import { Grid, Typography, InputAdornment, useMediaQuery, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { DataGridPro, GridRowId, GridEventListener, GridColDef } from '@mui/x-data-grid-pro';
import clsx from 'clsx';
import React, { useState, useCallback, useRef, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useMutation } from 'react-query';
import { logAmplitudeEvent } from '../../amplitude/amplitude';
import PageActionButton from '../../components/buttons/PageActionButton';
import {
    ACTIVATE_VEHICLE_BUTTON,
    DEACTIVATE_VEHICLE_BUTTON,
    DUPLICATE_VEHICLE_BUTTON,
    EDIT_VEHICLE_BUTTON,
    NEW_VEHICLE_BUTTON,
    VEHICLES_SCREEN,
    VEHICLES_SEARCH_INPUT,
} from '../../constants/amplitude/supplierKeys';
import { COLORS } from '../../constants/colors';
import { Severity, useNotificationContext } from '../../context/NotificationContext';
import useDataGridStyles from '../../styles/DataGrid';
import theme from '../../styles/theme';
import { archiveVehicle, restoreVehicle, useVehicle, useVehicles } from './api';
import { Vehicle } from './api/types';
import { ColorOption, getColorOption } from './colorOptions';
import AddVehicleModal from './components/AddVehicleModal';
import ConfirmToggleVehicleActivationModal from './components/ConfirmToggleVehicleActivationModal';
import VehicleCard from './components/VehicleCard';
import VehiclesLoadingSkeleton from './components/VehiclesLoadingSkeleton';
import { useVehiclesColumns } from './hooks/useVehiclesColumns';

const useStyles = makeStyles(styleTheme => ({
    mainWrapper: {
        alignItems: 'center',
        padding: 0,
    },
    mainWrapperTopPadding: { paddingTop: '3.5rem' },
    headerTitleWrapper: {
        marginTop: '1rem',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        [styleTheme.breakpoints.up('md')]: {
            justifyContent: 'space-between',
            flexDirection: 'row',
            marginTop: '2rem',
        },
    },
    headerTitle: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 0,
        [styleTheme.breakpoints.down('sm')]: {
            width: '100%',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '0.5rem',
        },
    },
    headerTypo: {
        color: COLORS.BLUE,
        fontSize: '1.5rem',
        fontWeight: 700,
        paddingRight: '1rem',
        [styleTheme.breakpoints.down('sm')]: { fontSize: '1.3rem' },
    },
    content: { order: 2 },
}));

export const TABLE_HEIGHT = 'calc(100vh - 263px)';
let searchTimeoutId: ReturnType<typeof setTimeout> | number = -1;

const Vehicles = () => {
    const classes = useStyles();
    const dataGridClases = useDataGridStyles();
    const { t } = useTranslation();
    const [searchPhrase, setSearchPhrase] = useState('');
    const [debouncedSearchPhrase, setDebouncedSearchPhrase] = useState('');
    const [isAddVehicleModalOpen, setIsAddVehicleModalOpen] = useState(false);
    const [isEditVehicleModalOpen, setIsEditVehicleModalOpen] = useState(false);
    const [isConfirmToggleIsActiveModalOpen, setIsConfirmToggleIsActiveModalOpen] = useState(false);
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const selectedVehicle = useRef<Vehicle>();
    const selectedId = useRef<number | null>(null);
    const { data: vehicles = [], isFetching, isLoading, refetch } = useVehicles(debouncedSearchPhrase, 100);
    const contentKey = useRef<Date>(new Date());
    const [isInitialLoad, setIsInitialLoad] = useState(true);
    const { showNotification } = useNotificationContext();

    const { data: vehicleForEdit } = useVehicle(selectedId.current, contentKey.current);

    const { mutate: restoreVehicleMutation, isLoading: isLoadingRestore } = useMutation(restoreVehicle, {
        onSuccess: async () => {
            showNotification(t('alert.edit.successVehicleChange'), Severity.Info);
            await refetch();
        },
        onError: (error: Error) => {
            showNotification(t('alert.edit.error'), Severity.Error);
        },
        onSettled: () => {
            setIsConfirmToggleIsActiveModalOpen(false);
            selectedId.current = null;
        },
    });

    const { mutate: archiveVehicleMutation, isLoading: isLoadingArchive } = useMutation(archiveVehicle, {
        onSuccess: async () => {
            showNotification(t('alert.edit.successVehicleChange'), Severity.Info);
            await refetch();
        },
        onError: (error: Error) => {
            showNotification(t('alert.edit.error'), Severity.Error);
        },
        onSettled: () => {
            setIsConfirmToggleIsActiveModalOpen(false);
            selectedId.current = null;
        },
    });

    const isNoRows = useMemo(() => vehicles.length === 0, [vehicles.length]);

    const tableWrapperHeight = useMemo(() => {
        return isNoRows ? 'auto' : TABLE_HEIGHT;
    }, [isNoRows]);

    const tableHeight = useMemo(() => (isNoRows ? TABLE_HEIGHT : '100%'), [isNoRows]);

    useEffect(() => {
        logAmplitudeEvent(VEHICLES_SCREEN);
    }, []);

    useEffect(() => {
        if (!isLoading && isInitialLoad) {
            setIsInitialLoad(false);
        }
    }, [isLoading, isInitialLoad]);

    const handleSearchInputOnChange = useCallback(
        (value: string) => {
            setSearchPhrase(value);
            clearTimeout(searchTimeoutId);
            searchTimeoutId = setTimeout(() => {
                setDebouncedSearchPhrase(value);
            }, 600);
        },
        [setDebouncedSearchPhrase]
    );

    const onToggleStatus = (vehicle: Vehicle) => {
        selectedId.current = vehicle.id;

        setIsConfirmToggleIsActiveModalOpen(true);
        logAmplitudeEvent(vehicle.isArchived ? ACTIVATE_VEHICLE_BUTTON : DEACTIVATE_VEHICLE_BUTTON);
    };

    const onEdit = (vehicle: Vehicle) => {
        contentKey.current = new Date();
        selectedId.current = vehicle.id;
        setIsEditVehicleModalOpen(true);
        logAmplitudeEvent(EDIT_VEHICLE_BUTTON);
    };

    const onDuplicateClick = (vehicle: Vehicle) => {
        selectedVehicle.current = {
            id: -1,
            licensePlate: '',
            brand: vehicle.brand,
            model: vehicle.model,
            identifier: '',
            pictures: [],
            status: '',
            color: getColorOption(vehicle.color || '') as ColorOption,
        };

        setIsAddVehicleModalOpen(true);
        logAmplitudeEvent(DUPLICATE_VEHICLE_BUTTON);
    };

    const columns = useVehiclesColumns(onToggleStatus, onEdit, onDuplicateClick);

    const handleToggleActivation = (id: number, isDeactivated: boolean) => {
        if (isDeactivated) {
            restoreVehicleMutation(id);
        } else {
            archiveVehicleMutation(id);
        }
    };

    const onRowClick = useCallback<GridEventListener<'rowClick'>>((params: { id: GridRowId; row: Vehicle }) => {
        onEdit(params.row);
    }, []);

    const handleCloseAddEditModal = () => {
        setIsAddVehicleModalOpen(false);
        setIsEditVehicleModalOpen(false);
        selectedId.current = null;
        selectedVehicle.current = undefined;
    };

    const handleSubmit = async () => {
        await refetch();
        setIsAddVehicleModalOpen(false);
        setIsEditVehicleModalOpen(false);
        selectedId.current = null;
        selectedVehicle.current = undefined;
    };

    if (isInitialLoad && isLoading) {
        return <VehiclesLoadingSkeleton />;
    }

    return (
        <Grid container className={clsx(classes.mainWrapper)}>
            <Grid item xs={12} className={classes.headerTitleWrapper}>
                <Grid item className={classes.headerTitle}>
                    <Typography className={classes.headerTypo}>{t('pages.vehicles.vehicles')}</Typography>
                </Grid>
                <Grid item>
                    <PageActionButton
                        onClick={() => {
                            setIsAddVehicleModalOpen(true);
                            logAmplitudeEvent(NEW_VEHICLE_BUTTON);
                        }}
                        isLoading={isFetching}
                    >
                        <AddIcon style={{ paddingRight: '0.5rem' }} />
                        {t('pages.vehicles.newVehicle')}
                    </PageActionButton>
                </Grid>
            </Grid>
            <Grid container sx={{ marginTop: isMobile ? '1rem' : '2rem' }}>
                <Grid item xs={12} md={8} xl={8}>
                    <CustomTextField
                        id="searchVehicles"
                        autoComplete="off"
                        value={searchPhrase}
                        noHelperTextSpace
                        onChange={event => {
                            handleSearchInputOnChange(event.target.value);
                        }}
                        label={t('pages.vehicles.search')}
                        name="search"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                        onBlur={() => logAmplitudeEvent(VEHICLES_SEARCH_INPUT)}
                    />
                </Grid>
            </Grid>
            {!isMobile ? (
                <Box
                    sx={{
                        width: '100%',
                        height: tableWrapperHeight,
                    }}
                >
                    <DataGridPro
                        style={{ height: tableHeight }}
                        className={clsx({ [dataGridClases.dataGridNoRows]: isNoRows }, dataGridClases.dataGrid)}
                        hideFooter
                        loading={isFetching || isLoading}
                        disableColumnSelector
                        rows={vehicles}
                        columns={columns as GridColDef[]}
                        slots={{
                            noRowsOverlay: () => (
                                <NoResults
                                    icon={
                                        <DirectionsCarFilledIcon
                                            style={{
                                                color: COLORS.SLATE_GREY,
                                                width: '48px',
                                                height: '48px',
                                            }}
                                        />
                                    }
                                    text={t('pages.vehicles.notAvailable')}
                                    description={t('pages.vehicles.notAvailableDesc')}
                                    additionalContent={
                                        <Box marginTop="0.5rem" onClick={() => setIsAddVehicleModalOpen(true)}>
                                            <Typography
                                                style={{
                                                    color: COLORS.SLATE_GREY,
                                                    textDecoration: 'underline',
                                                    cursor: 'pointer',
                                                    textUnderlineOffset: '.3rem',
                                                }}
                                            >
                                                {t('pages.vehicles.createOne')}
                                            </Typography>
                                        </Box>
                                    }
                                />
                            ),
                        }}
                        onRowClick={onRowClick}
                    />
                </Box>
            ) : (
                vehicles.map(vehicle => (
                    <VehicleCard
                        key={vehicle.id}
                        vehicle={vehicle}
                        onToggleStatus={onToggleStatus}
                        onEdit={onEdit}
                        onDuplicateClick={onDuplicateClick}
                    />
                ))
            )}
            {isConfirmToggleIsActiveModalOpen && vehicleForEdit && (
                <ConfirmToggleVehicleActivationModal
                    id={vehicleForEdit.id}
                    isDeactivated={vehicleForEdit.isArchived ?? false}
                    onClose={() => setIsConfirmToggleIsActiveModalOpen(false)}
                    onToggle={handleToggleActivation}
                    isLoading={isLoadingArchive || isLoadingRestore}
                />
            )}
            {isAddVehicleModalOpen && (
                <AddVehicleModal
                    onClose={handleCloseAddEditModal}
                    onSuccess={handleSubmit}
                    isOpen={isAddVehicleModalOpen}
                    vehicle={selectedVehicle.current}
                />
            )}
            {isEditVehicleModalOpen && vehicleForEdit && (
                <AddVehicleModal
                    onClose={handleCloseAddEditModal}
                    onSuccess={handleSubmit}
                    isOpen={isEditVehicleModalOpen}
                    vehicle={vehicleForEdit as Vehicle}
                />
            )}
        </Grid>
    );
};

export default Vehicles;
